import React from 'react'
import { withRouter } from 'react-router-dom'
import Category from './singleComponents/Category';
import Novelty from './singleComponents/Novelty';
import PopularNews from './singleComponents/PopularNews';
import Pagination from 'react-js-pagination'
import BodiesHeader from './singleComponents/BodiesHeader';
import BodiesChembarSingle from './singleComponents/BodiesChembarSingle';


class BodiesOfChember extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            bodiesChember: [],
            data: [],
            sort: {
                column : null,
                direction: 'desc'
            },
            activePage: 1,
            newsPerPage: 10,
            numberOfPagButton: 5,
            totalNews: 0,
            searchField: false,
            temporaryFiltered: [],
            showDeleteModal: false,
            novelty: '',
            categories: '',
        }
    }

    

    componentDidMount() {
        this.props.getBodiesChember()
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        let _pagChemb, _bodiesChember, _totalChemb
        if(nextProps.bodiesChember !== prevProps.bodiesChember) {
            _bodiesChember = nextProps.bodiesChember
            _pagChemb = nextProps.bodiesChember.slice(prevProps.activePage*prevProps.newsPerPage - prevProps.newsPerPage,
                prevProps.activePage*prevProps.newsPerPage, [])
            _totalChemb = nextProps.bodiesChember.length
        } else {
            _pagChemb = nextProps.bodiesChember.slice(prevProps.activePage*prevProps.newsPerPage - prevProps.newsPerPage,
                prevProps.activePage*prevProps.newsPerPage, [])
            _totalChemb = nextProps.bodiesChember.length
        }
        return {
            bodiesChember: _bodiesChember,
            data: _pagChemb,
            totalNews: _totalChemb,
        }
    }   

    setActivePage = (currentPage) => {
        let pagNews = this.props.bodiesChember
        this.setState({
            activePage: currentPage,
            data: pagNews.slice(currentPage*this.state.newsPerPage - this.state.newsPerPage,
                    currentPage*this.state.newsPerPage, [])
        })
    }


    render() {
        return (
            <div>
                <BodiesHeader />
                <div className="contain-wrapp padding-bot50">	
		            <div className="container">
			            <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <aside>
                                    <div className="widget">
                                        <h5 className="widget-head">Pratite nas</h5>
                                        <a href="https://www.facebook.com/MNEdentalchamber/" className="btn btn-facebook btn-icon btn-block">Facebook <i className="fa fa-facebook"></i></a>
                                        <a href="https://www.instagram.com/stomatoloskakomoracg/" className="btn btn-instagram btn-icon btn-block">Instagram <i className="fa fa-instagram"></i></a>
                                    </div>
                                </aside>
                            </div>

                            <div className="col-md-9 col-sm-9">
                                {
                                    this.state.data !== undefined ?
                                    this.state.data.map(item => {
                                        return <BodiesChembarSingle 
                                            key={item.id}
                                            id={item.id}
                                            title={item.title}
                                            date={item.date}
                                            content={item.content}
                                            imgTitle={item.imgTitle}
                                        />
                                    })
                                    : ""
                                }

                                <Pagination     
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.newsPerPage}
                                    totalItemsCount={this.state.totalNews}
                                    pageRangeDisplayed={this.state.numberOfPagButton}
                                    onChange={this.setActivePage}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(BodiesOfChember)