import { call, put, takeEvery } from 'redux-saga/effects'
import { aboutConstants } from '../constants/about.constants';
import { about_api, getBodiesChemberDetails_api, getBodiesChember_api, getFreeInfo_api } from '../api/about.api';
import { getAboutContent_failure, getAboutContent_success, getBodiesChemberDetails_failure, getBodiesChemberDetails_success, getBodiesChember_failure, getBodiesChember_success, getFreeInfo_failure, getFreeInfo_success } from '../actions/aboute.action';


export function* getContent() {
    const response = yield call(about_api)
    if(!response || !response.data) {
        return yield put(getAboutContent_failure('Internal server error for geting about content'))
    }
    if(response.status === 200) {   
        return yield put(getAboutContent_success(response.data))
    } else {
        return yield put(getAboutContent_failure('Eror for geting about content'))
    }
}

export function* getBodiesChember() {
    const response = yield call(getBodiesChember_api)
    if(!response || !response.data) {
        return yield put(getBodiesChember_failure('Internal server error for geting about bodies of chember'))
    }
    if(response.status === 200) {   
        return yield put(getBodiesChember_success(response.data))
    } else {
        return yield put(getBodiesChember_failure('Eror for geting about bodies of chember'))
    }
}

export function* getBodiesChemberDetails(action) {
    const response = yield call(getBodiesChemberDetails_api, action.payload)
    if(!response || !response.data) {
        return yield put(getBodiesChemberDetails_failure('Internal server error for get novelty details'))
    }
    if(response.status === 200) {
        return yield put(getBodiesChemberDetails_success(response.data))
    } else {
        return yield put(getBodiesChemberDetails_failure('Internal server error for get novelty details'))
    }
}

export function* getFreeInfo() {
    console.log('saga')
    const response = yield call(getFreeInfo_api)
    if(!response || !response.data) {
        return yield put(getFreeInfo_failure('Internal server error for geting about free info'))
    }
    if(response.status === 200) {   
        return yield put(getFreeInfo_success(response.data))
    } else {
        return yield put(getFreeInfo_failure('Eror for geting about free info'))
    }
}


export function* aboutSaga() {
    yield takeEvery(aboutConstants.GETABOUTCONTENT_REQUEST, getContent)
    yield takeEvery(aboutConstants.GETBODIESOFCHEMBER_REQUEST, getBodiesChember)
    yield takeEvery(aboutConstants.GETBODIESCHEMBERDETAILS_REQUEST, getBodiesChemberDetails)
    yield takeEvery(aboutConstants.GETFREEINFO_REQUEST, getFreeInfo)
}