import React from 'react'
import { withRouter } from 'react-router-dom'
import PageHeader from './PageHeader';
// import PageHeader from './singleComponents/PageHeader';
// import NoveltyCnt from '../containers/NoveltyCnt';

class BodiesChemberDetailsSingleItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            bodiesChemberDetails: []
        }
    }

    componentDidMount() {
        // this.props.getDetails(parseInt(this.props.novelty.id));
        this.props.getDetails(localStorage.getItem('bodiesChember'));
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         details: nextProps.details
    //     })
    // }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if(nextProps.bodiesChemberDetails !== prevProps.bodiesChemberDetails) {
            return {
                bodiesChemberDetails: nextProps.bodiesChemberDetails
            }
        }
        return null
    }

    render() {
        return(
            <article className="row post">
                <div className="col-md-12" style={{borderBottom: '1px solid lightgray', marginTop:"20px", marginLeft: '20px', marginRight: '20px', textAlign:"center" }}>
                    {
                        this.state.bodiesChemberDetails.file !== null
                        ?
                        <div style={{width: '60%', paddingBottom: '15px'}}>
                            <img src={`https://laravel.stomkomcg.me/`+this.state.bodiesChemberDetails.file} className="img-responsive" alt="img" style={{width:'95%', paddingTop: '10px'}}/>
                        </div>
                        : ""
                    }

                    <h5> {this.state.bodiesChemberDetails.title} </h5>
                    <ul className="post-meta">
                        <li><i className="fa fa-calendar"></i> {this.state.bodiesChemberDetails.date}</li>
                        <li><i className="fa fa-user"></i> Admin</li>
                    </ul>
                    {/* <p style={{paddingLeft:'15px'}}> */}
                        {
                          this.state.bodiesChemberDetails ? <div dangerouslySetInnerHTML={{__html:  this.state.bodiesChemberDetails.full_text}}></div> :  this.state.bodiesChemberDetails.content
                        }
                    {/* </p> */}
                    
                </div>
        </article>
        )
    }
}

export default withRouter(BodiesChemberDetailsSingleItem)