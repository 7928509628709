import { connect } from 'react-redux'
import FreeInfo from '../components/FreeInfo'
import { getFreeInfo_request } from '../actions/aboute.action'

const mapStateToProps = state => ({
    freeInfo: state.aboutReducer.freeInfo
})
const mapDispatchToProps = dispatch => ({
    getFreeInfo: () => dispatch(getFreeInfo_request())
})

const FreeInfoCnt = connect (
    mapStateToProps,
    mapDispatchToProps
)(FreeInfo)

export default FreeInfoCnt