import axios from 'axios'

// var apiUrl = window.location.href.indexOf('stomkomcg.me') >= 0 ? 'https://laravel.stomkomcg.me' : 'http://api.zk.test';

 var apiUrl = "https://laravel.stomkomcg.me"
//var apiUrl = "http://api.zk.test"


export function about_api() {
    return axios.get(apiUrl + '/about')
}

export function getBodiesChember_api() {
    return axios.get(apiUrl + '/bodiesChember')
}

export function getBodiesChemberDetails_api(id) {
    return axios.get(apiUrl + '/bodiesChember/'+id)
}

export function getFreeInfo_api() {
    return axios.get(apiUrl + '/freeInfo')
}