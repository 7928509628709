export const aboutConstants = {
   GETABOUTCONTENT_REQUEST: 'GET_ABOUT_CONTENT_REQUEST',
   GETABOUTCONTENT_SUCCESS: 'GET_ABOUT_CONTENT_SUCCESS',
   GETABOUTCONTENT_FAILURE: 'GET_ABOUT_CONTENT_FAILURE',

   GETBODIESOFCHEMBER_REQUEST: "GET_BODIES_OF_CHEMBER_REQUEST",
   GETBODIESOFCHEMBER_SUCCESS: "GET_BODIES_OF_CHEMBER_SUCCESS",
   GETBODIESOFCHEMBER_FAILURE: "GET_BODIES_OF_CHEMBER_FAILURE",

   GETBODIESCHEMBERDETAILS_REQUEST: "GET_BODIESCHEMBER_DETAILS_REQUEST",
   GETBODIESCHEMBERDETAILS_SUCCESS: "GET_BODIESCHEMBER_DETAILS_SUCCESS",
   GETBODIESCHEMBERDETAILS_FAILURE: "GET_BODIESCHEMBER_DETAILS_FAILURE",

   GETFREEINFO_REQUEST: "GET_FREE_INFO_REQUEST",
   GETFREEINFO_SUCCESS: "GET_FREE_INFO_SUCCESS",
   GETFREEINFO_FAILURE: "GET_FREE_INFO_FAILURE",

   GETFREEINFODETAILS_REQUEST: "GET_FREEINFO_DETAILS_REQUEST",
   GETFREEINFODETAILS_SUCCESS: "GET_FREEINFO_DETAILS_SUCCESS",
   GETFREEINFODETAILS_FAILURE: "GET_FREEINFO_DETAILS_FAILURE",
}