import React from 'react'

export default function FreeHeader () {
        return (
            <div>
                <div className="clearfix"></div>
                <div className="free-head">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 pageHeader">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}