export const newsConstants = {
    GETNEWS_REQUEST: 'GET_NEWS_REQUEST',
    GETNEWS_SUCCESS: 'GET_NEWS_SUCCESS',
    GETNEWS_FAILURE: 'GET_NEWS_FAILURE',

    GETNOVELTYDETAILS_REQUEST: "GETNOVELTY_DETAILS_REQUEST",
    GETNOVELTYDETAILS_SUCCESS: "GETNOVELTY_DETAILS_SUCCESS",
    GETNOVELTYDETAILS_FAILURE: "GETNOVELTY_DETAILS_FAILURE",

    GETNEWSFORCATEGORY_REQUEST: "GETNEWSFORCATEGORY_REQUEST",
    GETNEWSFORCATEGORY_SUCCESS: "GETNEWSFORCATEGORY_SUCCESS",
    GETNEWSFORCATEGORY_FAILURE: "GETNEWSFORCATEGORY_FAILURE",

    GETNEWSFORFOOTER_REQUEST: 'GETNEWSFORFOOTER_REQUEST',
    GETNEWSFORFOOTER_SUCCESS: 'GETNEWSFORFOOTER_SUCCESS',
    GETNEWSFORFOOTER_FAILURE: 'GETNEWSFORFOOTER_FAILURE',

    GETPOPULARNEWS_REQUEST: "GET_POPULAR_NEWS_REQUEST",
    GETPOPULARNEWS_SUCCESS: "GET_POPULER_NEWS_SUCCESS",
    GETPOPULARNEWS_FAILURE: "GET_POPULER_NEWS_FAILURE",

    GETLASTNEW_REQUEST: "GET_LAST_NEW_REQUEST",
    GETLASTNEW_SUCCESS: "GET_LAST_NEW_SUCCESS",
    GETLASTNEW_FAILURE: "GET_LAST_NEW_FAILURE"
}