import { aboutConstants } from "../constants/about.constants";

const initialState = {
    content: '',
    bodiesChember: [],
    bodiesChemberDetails: [],
    freeInfo: [],
}

export default function aboutReducer(state = initialState, action) {
    switch(action.type) {
        case aboutConstants.GETABOUTCONTENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case aboutConstants.GETABOUTCONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                content: action.payload
            }
        case aboutConstants.GETABOUTCONTENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case aboutConstants.GETBODIESOFCHEMBER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case aboutConstants.GETBODIESOFCHEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                bodiesChember: action.payload
            }
        case aboutConstants.GETBODIESOFCHEMBER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case aboutConstants.GETBODIESCHEMBERDETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                bodiesChemberDetails: []
            }
        case aboutConstants.GETBODIESCHEMBERDETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                news: state.news,
                bodiesChemberDetails: action.payload
            }
        case aboutConstants.GETBODIESCHEMBERDETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case aboutConstants.GETFREEINFO_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case aboutConstants.GETFREEINFO_SUCCESS:
            return {
                ...state,
                loading: false,
                freeInfo: action.payload
            }
        case aboutConstants.GETFREEINFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}