import { aboutConstants } from "../constants/about.constants";

export function getAboutContent_request(content) {
    return {
        type: aboutConstants.GETABOUTCONTENT_REQUEST,
        payload: content
    }
}
export function getAboutContent_success(content) {
    return {
        type: aboutConstants.GETABOUTCONTENT_SUCCESS,
        payload: content
    }
}
export function getAboutContent_failure(error) {
    return {
        type: aboutConstants.GETABOUTCONTENT_REQUEST,
        payload: error
    }
}
//BODIES OF CHEMBER
export function getBodiesChember_request(content) {
    return {
        type: aboutConstants.GETBODIESOFCHEMBER_REQUEST,
        payload: content
    }
}
export function getBodiesChember_success(content) {
    return {
        type: aboutConstants.GETBODIESOFCHEMBER_SUCCESS,
        payload: content
    }
}
export function getBodiesChember_failure(error) {
    return {
        type: aboutConstants.GETBODIESOFCHEMBER_FAILURE,
        payload: error
    }
}
//details for bodies chember
export function getBodiesChemberDetails_request(novelty) {
    return {
        type: aboutConstants.GETBODIESCHEMBERDETAILS_REQUEST,
        payload: novelty
    }
}

export function getBodiesChemberDetails_success(novelty) {
    return {
        type: aboutConstants.GETBODIESCHEMBERDETAILS_SUCCESS,
        payload: novelty
    }
}

export function getBodiesChemberDetails_failure(error) {
    return {
        type: aboutConstants.GETBODIESCHEMBERDETAILS_FAILURE,
        payload: error
    }
}
//free info
export function getFreeInfo_request(content) {
    return {
        type: aboutConstants.GETFREEINFO_REQUEST,
        payload: content
    }
}
export function getFreeInfo_success(content) {
    return {
        type: aboutConstants.GETFREEINFO_SUCCESS,
        payload: content
    }
}
export function getFreeInfo_failure(error) {
    return {
        type: aboutConstants.GETFREEINFO_FAILURE,
        payload: error
    }
}

