import { connect } from 'react-redux'
import BodiesOfChember from '../components/BodiesOfChember'
import { getBodiesChember_request } from '../actions/aboute.action'

const mapStateToProps = state => ({
    bodiesChember: state.aboutReducer.bodiesChember
})
const mapDispatchToProps = dispatch => ({
    getBodiesChember: () => dispatch(getBodiesChember_request())
})

const BodiesOfChemberCnt = connect (
    mapStateToProps,
    mapDispatchToProps
)(BodiesOfChember)

export default BodiesOfChemberCnt