import React from 'react'
import { withRouter } from 'react-router-dom'
import PageHeader from './singleComponents/PageHeader';
import BodiesChemberDetailsCnt from '../containers/BodiesChemberDetailsCnt';
import BodiesChembarSingle from './singleComponents/BodiesChembarSingle';
import BodiesChemberDetailsSingleItem from './singleComponents/BodiesChemberDetailsSingleItem';

class BodiesChemberDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            details: []
        }
    }

    componentDidMount() {
        // this.props.getDetails(parseInt(this.props.novelty.id));
        this.props.getDetails(localStorage.getItem('bodiesChember'));
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if(nextProps.bodiesChemberDetails !== prevProps.bodiesChemberDetails) {
            return {
                details: nextProps.bodiesChemberDetails
            }
        }
        return null
    }

    render() {
        console.log(this.state.details)
        return(
            <div>
                <PageHeader />
                
                <div className="col-md-6 col-sm-6 col-sm-offset-3 pageDetailsPadding">
                {   
                     <BodiesChemberDetailsSingleItem 
                     key={this.state.id}
                     id={this.state.id}
                     title={this.state.title}
                     date={this.state.date}
                     content={this.state.content}
                     imgTitle={this.state.imgTitle}
                 />
                }
                {
                    this.state.details.file?.length > 5 ?
                    // <p >Vijest mozete pogledati <a href={this.state.details.file}>ovdje</a></p>
                    <p style={{ paddingLeft: '20px' }}>Vijest možete pogledati <a href={`https://laravel.stomkomcg.me/${this.state.details.file}`} target="_blank">ovdje</a></p>
                    : ""
                }
                </div>
            </div>
        )
    }
}

export default withRouter(BodiesChemberDetails)