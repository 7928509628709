import React from 'react'
import { withRouter } from 'react-router-dom'
import PageHeader from './singleComponents/PageHeader';

class Educations extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            userDetails: []
        }
    }

    componentDidMount() {
    }
  
    render() {
        return(
            <div>
                <PageHeader />

                <div className="contain-wrapp gray-container paddingbot-clear">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-sm-offset-3">
                                <div className="title-head">
                                    <img src="img/handson2.jpg" className="img-responsive" alt="" />
                                    <p className='text-center' style={{'paddingTop': '10px'}}>HANDS ON KURS IZ ENDODONCIJE</p>
                                </div>
                                <div className='text-center' style={{'fontStyle':'italic'}}>
                                    <p> Dana 15. oktobra 2022. godine Stomatološka komora Crne Gore organizovala je Hands on kurs iz oblasti endodoncije. Predavač je bio <span style={{'fontWeight':'bold'}}>Prof. dr Goran Tošić.</span> </p>
                                    <p>Predavanje se sastojalo od teorijskog i praktičnog dijela.</p>
                                    <p>Teorijski dio : Mašinska obrada kanalnog sistema, istorija, strategije, izbor, karakteristike različitih sistema, morfološke karakteristike i trodnimenzionalni aspekt kanalnog sistema. </p>
                                    <p>Radni/praktični dio : Demonstracija i manuelni trening: način rada, karakteristike, manipulacija, održavanje mašinskih sistema u endodonciji, prateći instrumentarijum koferdam i sukcija, lupe, apeks locator, Potaper Next, Potaper Gold system, na plastičnim bokovima i na izvađenim zubima.</p>
                                </div>
                               </div>
                            <div className="col-md-5">
                            </div>
                        </div>

                        <div className="row" style={{'paddingTop':'20px'}}>
                            <div className="col-md-6 col-sm-6 col-sm-offset-3">
                                <div className="title-head">
                                    <img src="img/simpozijum1.jpg" className="img-responsive" alt="" />
                                    <p className='text-center' style={{'paddingTop': '10px'}}>SIMPOZIJUM</p>
                                </div>
                                <div className='text-center' style={{'fontStyle':'italic'}}>
                                    <p>Dana 26. novembra 2022. godine Stomatološka komora Crne Gore organizovala je Simpozijum doktora stomatologije.</p>
                                    <p className='text-left'>
                                        <p style={{'padding':'10px', 'fontWeight':'bold'}}>Predavanja su održali profesori i saradnici Stomatološkog fakulteta iz Pančeva:</p>
                                        <ul >
                                            <li>Doc. dr Nikola Radović, specijalista parodontologije i oralne medicine</li>
                                            <li>Doc. dr Jovana Milutinović, specijalista ortopedije vilica</li>
                                            <li>Doc. dr Marko Pejović, specijalista oralne hirurgije</li>
                                            <li>Doc. dr Katarina Kalevski, specijalista dječije i preventivne stomatologije</li>
                                            <li>Doc. dr Miljan Puletić, specijalista parodontologije i oralne medicine</li>
                                        </ul>
                                    </p>
                                </div>
                                <div className='text-center' style={{'fontStyle':'italic'}}>
                                    <p>Teme predavanja su bile:</p>
                                    <p className='text-left'>
                                        <p style={{'padding':'10px', 'fontWeight':'bold'}}>Predavanja su održali profesori i saradnici Stomatološkog fakulteta iz Pančeva:</p>
                                        <ul >
                                            <li>‘’Primena VELScop lampe u dijagnostici oralno-medicinskih oboljenja različite etiologije’’ Doc. dr Nikola Radović </li>
                                            <li>“Ortodoncija danas - veština ispravljanja zuba ili nešto više?’’ Doc. dr Jovana Milutinović</li>
                                            <li>“Karijes ranog detinjstva –dileme u svakodnevnoj kliničkoj praksi“ Doc. dr Katarina Kalevski</li>
                                            <li>“Pacijenti na antiagregacionoj i antikoagulantnoj terapiji u stomatološkoj praksi - savremeni protokol i pravno medicinski aspekti komplikacija “ Doc. dr Marko Pejović</li>
                                            <li>“Regenerativna terapija parodontopatije” Doc. Dr Miljan Puletić</li>
                                        </ul>
                                    </p>
                                </div>
                               </div>
                            <div className="col-md-5">
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-sm-offset-3">
                                <div className="title-head">
                                    <img src="img/panel.jpg" className="img-responsive" alt="" />
                                    <p className='text-center' style={{'paddingTop': '10px'}}>FORUM - PANEL DISKUSIJA</p>
                                </div>
                                <div className='text-left' style={{'fontStyle':'italic'}}>
                                    <p> Stomatološka komora Crne Gore je dana 20.01.2023. godine organizovala prvu Panel diskusiju na temu: <span style={{fontWeight:'bold'}}>„Savremeni sistemi pružanja stomatološke zdravstvene zaštite – sa posebnim osvrtom na organizaciju stomatološke zdravstvene zaštite u sistemu javnog zdravstva“</span> . </p>
                                    <p style={{paddingTop:'5px'}}>Povod za organizaciju Panela je bila jedinstvena prilika za otvoreni razgovor o savremenim sistemima ostvarivanja stomatološke zdravstvene zaštite, i mogućnost da se čuju iskustva iz nekoliko različitih zemalja, koje imaju uređene sisteme.</p>
                                    <p style={{paddingTop:'5px'}}>Panel je bio namijenjen svim doktorima stomatologije u Crnoj Gori, kao i široj crnogorskoj zainteresovanoj javnosti. </p>
                                </div>
                                <p className='text-left'>
                                        <p style={{'padding':'10px', 'fontWeight':'bold'}}>Panelisti su bili:</p>
                                        <ul >
                                            <li>Predsjednik Stomatološke sekcije Slovenačkog ljekarskog društva Prim. Matej Leskošek dr dent.med.,</li>
                                            <li>Predsjednik Hrvatske komore Dentalne medicine Mr. Sc. Hrvoje Pezo,</li>
                                            <li>Predstavnica Svjetske stomatološke organizacije, Dr Đerdana Ivošević</li>
                                            <li>Predsjednik Stomatološke komore Makedonije, Dr Marijan Denkovski</li>
                                            <li>Predśednik Stomatološke komore Crne Gore, Dr Vladimir V. Jovetić.</li>
                                        </ul>
                                </p>
                                <p style={{padding:'10px'}}>Zaključak Prve panel diskusije bio je da su zajednički napori i uključenost nadležnih institucija, ključ uspjeha u procesu unapređenja trenutnog sistema pružanja stomatološke zaštite u Crnoj Gori.</p>
                                <p style={{padding:'10px'}}>Stomatološka komora Crne Gore je organizacijom ovog panela željela još jednom da podsjeti na značaj kreiranja temeljnog i kvalitetnog sistema, sa čijim modelom bi svi bili saglasni.</p>
                                <p style={{padding:'10px'}}>Ovaj događaj okupio je brojne domaće i regionalne stručnjake iz oblasti stomatologije, kao i predstavnike nadležne institucije.</p>
                                <p style={{padding:'10px'}}>Panelu su prisustvovali predstavnici Ministarstva zdravlja, Fonda za zdravstveno osiguranje, Doma zdravlja Podgorica, Ministarstva prosvjete, Ministarstva finansija, Instituta za javno zdravlje, Stomatološke poliklinike kao I Medicinskog fakulteta.</p>
                               </div>
                            <div className="col-md-5">
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-sm-offset-3">
                                <div className="title-head">
                                    <img src="img/kongres.jpg" className="img-responsive" alt="" />
                                    <p className='text-center' style={{'paddingTop': '10px'}}>IV KONGES STOMATOLOGA CRNE GORE SA MEĐUNARODNIM UČEŠĆEM</p>
                                </div>
                                <div className='text-left' style={{'fontStyle':'italic'}}>
                                    <p style={{padding:'10px'}}>IV Kongres stomatologa Crne Gore sa međunarodnim učešćem, pod sloganom “IZAZOVI SAVREMENE STOMATOLOGIJE” organizovan je u preiodu od 27-29. aprila 2023. godine u hotelu “Splendid” u Budvi.</p>
                                    <p style={{padding:'10px'}}>Stomatološki kongres okupio je preko 400 učesnika iz Crne Gore, Srbije, Republike Srpske, Federacije BIH, Makedonije i Hrvatske.</p>
                                    <p style={{padding:'10px'}}>Na Kongresu učesnici su imali prilike da čuju predavanja od 25 eminentnih predavača, koji su obuhvatili teme iz oblasti: Oralne i Maksilofacijalne hirurgije, Dječije stomatologije, Stomatološke protetike i Edodoncije.</p>
                                    <p style={{padding:'10px'}}>Na svečanom otvaranju koje se održalo 27. aprila 2023. godine prisutnim gostima, zvanicama i stranim delegacijama obratio se predśednik Komore dr Vladimir V. Jovetić, kao i dr Mirjana Đuričković, rukovodilac studijskog programa Stomatologija na Medicinskom fakultetu.</p>
                                    <p style={{padding:'10px'}}>Kongres je svečano otvorio Prim dr Milenko Lainović, prvi stomatolog Crne Gore.</p>
                                    <p style={{padding:'10px'}}>Svečano otvaranje Kongresa podržali su predsjednik Odbora za zdravstvo, rad i socijalno staranje dr Srđan Pavićević, državni sekretar Ministarstva finansija Miloš Medenica, generalni direktor direktorata za kontrolu kvaliteta zdravstvene zaštite, unapređenje ljudskih resursa u zdravstvu i zakonitost funkcionisanja strukovnih regulatornih tijela dr Slobodan Mandić, kao i Ljekarska, Farmaceutska i Fizioterapeutska komora.</p>
                                </div>
                               </div>
                            <div className="col-md-5">
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Educations)