window.addEventListener("DOMContentLoaded", (event) => {
    const hederHover = document.getElementById('komora-hover')
    const hiddenDiv = document.getElementById("heder-hidden")
    const navbar = document.querySelector("#navbar-menu")
    const navbar_left = document.querySelector(".navbar-left")
    const navbar_left_first_li = navbar_left.querySelector("li")

    let i = 0;

        hederHover.onmouseover = function(e){
            hiddenDiv.style.display = 'block'
            navbar_left_first_li.style.marginRight = "-48px"
        }

        hederHover.addEventListener("click", (event) => {
            event.preventDefault();
            if (window.innerWidth < 993) {
            if (i === 0){
                hiddenDiv.style.display = 'block'
                navbar_left.style.paddingBottom = "200px"
                i = 1
            } else if (i === 1){
                hiddenDiv.style.display = 'none'
                navbar_left.style.paddingBottom = "0px"
                i = 0
            }
        }
        })

        navbar.addEventListener("mouseleave", (event) => {
            event.stopPropagation();
            hiddenDiv.style.display = 'none'
            navbar_left_first_li.style.marginRight = "0px"
        });
});