import { connect } from 'react-redux'
import { getBodiesChemberDetails_request } from '../actions/aboute.action'
import BodiesChemberDetailsSingleItem from '../components/singleComponents/BodiesChemberDetailsSingleItem'

const mapStateToProps = state => ({
    bodiesChemberDetails: state.aboutReducer.bodiesChemberDetails
})

const mapDispatchToProps = dispatch => ({
    getDetails: (id) => dispatch(getBodiesChemberDetails_request(id))
})

const BodiesChemberDetailsSingleItemCnt = connect (
    mapStateToProps,
    mapDispatchToProps
)(BodiesChemberDetailsSingleItem)

export default BodiesChemberDetailsSingleItemCnt